import React from 'react';
import { graphql } from 'gatsby';

// Previews
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import HeroImage from '../components/images/hero-image';
import HeroJournalArticle from '../components/homepage/hero-journal-article';
import HeroText from '../components/text/hero-text';
import LandscapeImages from '../components/homepage/landscape-images';
import PageLink from '../components/homepage/page-link';
import JournalArticles from '../components/homepage/journal-articles';
import Collections from '../components/homepage/collections';
import { HomepageSlideshow } from '../components/homepage/homepage-slideshow';
import { VideoBanner } from '../components/video/video-banner';
import { VideoWithControls } from '../components/video/video-with-controls';

const HomePage = ({ location, data }) => {
  const content = data.prismicHome.data.body.map((content, index) => {
    switch (content.slice_type) {
      case 'full_width_image':
        return (
          <React.Fragment key={`hero_image_${content.id}`}>
            {content.primary.image.gatsbyImageData !== null && (
              <HeroImage
                alt={content.primary.image.alt}
                image={content.primary.image}
                callToAction={content.primary.call_to_action_text.text}
                buttonText={content.primary.button_text}
                link={content.primary.pageLink}
              />
            )}
          </React.Fragment>
        );

      case 'hero_journal_article':
        return (
          <HeroJournalArticle
            link={content.primary.article.uid}
            data={content.primary.article.document.data}
            key={content.id}
          />
        );

      case 'slideshow':
        return <HomepageSlideshow content={content} key={content.id} />;

      case 'text':
        return (
          <HeroText
            key={content.id}
            text={content.primary.full_width_title}
            padding={`60px 0`}
            mobilePadding={'30px 0'}
          />
        );

      case 'landscape_images':
        return <LandscapeImages key={content.id} data={content.items} />;

      case 'page_link':
        return <PageLink key={content.id} data={content.primary} />;

      case 'journal_articles':
        return (
          <JournalArticles
            key={content.id}
            data={content.primary}
            articles={content.items}
          />
        );

      case 'collections':
        return <Collections key={content.id} collections={content.items} />;

      case 'full_width_video':
        return <VideoBanner content={content} key={content.id} />;

      case 'video_with_controls':
        return <VideoWithControls content={content} key={content.id} />;

      default:
        return null;
    }
  });

  return <>{content}</>;
};

export default withPrismicPreview(HomePage);

export const query = graphql`
  query Home {
    prismicHome {
      data {
        body {
          ... on PrismicHomeDataBodyCollections {
            slice_type
            id
            items {
              image {
                alt
                dimensions {
                  width
                  height
                }
                gatsbyImageData
              }
              title1 {
                text
              }
              pageLink: link {
                url
                target
              }
            }
          }
          ... on PrismicHomeDataBodyFullWidthImage {
            id
            slice_type
            primary {
              call_to_action_text {
                text
              }
              button_text
              pageLink: link {
                url
                target
              }
              image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomeDataBodyHeroJournalArticle {
            slice_type
            id
            primary {
              article {
                uid
                document {
                  ... on PrismicJournalArticle {
                    id
                    prismicId
                    data {
                      excerpt
                      image {
                        gatsbyImageData
                      }
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeDataBodyLandscapeImages {
            id
            slice_type
            items {
              pageLink: link {
                url
                target
              }
              call_to_action {
                text
              }
              button_text
              full_width_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomeDataBodyText {
            id
            slice_type
            primary {
              full_width_title {
                html
              }
            }
          }
          ... on PrismicHomeDataBodyPageLink {
            slice_type
            id
            primary {
              excerpt
              page_link {
                document {
                  __typename
                  ... on PrismicPage {
                    id
                    data {
                      body {
                        ... on PrismicPageDataBodyFullWidthImage {
                          id
                          primary {
                            image {
                              alt
                              gatsbyImageData
                            }
                          }
                        }
                      }
                      title {
                        text
                      }
                    }
                    prismicId
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicHomeDataBodyJournalArticles {
            id
            slice_type
            primary {
              journal_text {
                html
              }
              journal_title {
                html
              }
              link_text
            }
            items {
              articles {
                document {
                  ... on PrismicJournalArticle {
                    id
                    prismicId
                    data {
                      date(formatString: "MMMM ‘YY")
                      title {
                        text
                      }
                      image {
                        gatsbyImageData
                      }
                      body {
                        ... on PrismicJournalArticleDataBodyText {
                          id
                          primary {
                            text {
                              text
                            }
                          }
                        }
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicHomeDataBodySlideshow {
            id
            slice_type
            items {
              image {
                gatsbyImageData
                alt
              }
              button_text
              call_to_action_text {
                text
              }
              pageLink: link {
                url
                target
              }
              image_slide
              video {
                html
                width
                height
                embed_url
              }
            }
          }
          ... on PrismicHomeDataBodyFullWidthVideo {
            id
            slice_type
            primary {
              video {
                html
                width
                height
                embed_url
              }
              call_to_action_text {
                html
              }
              link
              button_text
            }
          }
          ... on PrismicHomeDataBodyVideoWithControls {
            id
            slice_type
            primary {
              video {
                html
                height
                width
                embed_url
              }
            }
          }
        }
      }
    }
  }
`;
