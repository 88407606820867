import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './carousel.css';

import { ContentZoom } from './content-zoom';

const Image = styled.img`
  position: relative;
  cursor: zoom-in;
`;

const Lightbox = styled.div`
  display: ${props => (props.displayLightbox === true ? `block` : `none`)};

  & > div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10;

    cursor: zoom-out;
    background-color: #fff;

    padding: 110px 20px 20px 20px;

    overflow: hidden;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    & img {
      width: auto;
      height: 100%;

      margin: 0 auto;
      padding: 0;
    }

    @media (max-width: 1200px) {
      padding: 80px 20px 20px 20px;

      & .carousel {
        max-height: calc(100vh - 80px);

        & img {
          max-height: calc(100vh - 121px);
        }
      }
    }
  }
`;

const CloseLightbox = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  color: #000;

  z-index: 100;

  line-height: 1;
  font-size: 20px;

  padding: 10px;
`;

export const DesktopImages = ({ images, alt }) => {
  const [isLightboxOpen, setLightBoxOpen] = useState(false);

  useEffect(() => {
    if (isLightboxOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isLightboxOpen]);

  if (images !== undefined) {
    const productImages = images
      .filter((image, index) => index !== images.length - 1)
      .map((image, index) => {
        return (
          <ContentZoom
            key={image.id}
            largeImageUrl={image.image.originalSrc}
            altText={alt}
          />
        );
      });

    const galleryImages = images
      .filter((image, index) => index !== images.length - 1)
      .map((image, index) => {
        return (
          <Image
            src={image.originalSrc}
            key={image.id}
            alt={alt}
            loading={index === 0 ? `eager` : `lazy`}
            fetchPriority={index === 0 ? `high` : ``}
          />
        );
      });

    return (
      <>
        <Carousel
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows={true}
        >
          {productImages}
        </Carousel>

        <Lightbox displayLightbox={isLightboxOpen}>
          <Div100vh>
            <div className="lightbox-wrapper">
              <CloseLightbox onClick={() => setLightBoxOpen(false)}>
                ✕
              </CloseLightbox>
              <Carousel
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                useKeyboardArrows={true}
              >
                {galleryImages}
              </Carousel>
            </div>
          </Div100vh>
        </Lightbox>
      </>
    );
  } else {
    return null;
  }
};
