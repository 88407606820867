import React from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// Components
import { SlideshowVideo } from '../video/slideshow-video';
import { GatsbyImage } from 'gatsby-plugin-image';

const SlideshowContainer = styled.div`
  position: relative;
  max-height: 700px;
  overflow: hidden;

  & .react-slideshow-fade-wrapper {
    max-height: 700px;
  }

  & .indicators {
    position: absolute;

    bottom: 15px;
    left: 0;
    right: 0;

    margin-top: 0;

    z-index: 10;
    padding: 0;
    margin: 0;

    & > li {
      margin: 0 4px !important;

      & .each-slideshow-indicator:before {
        background: #fff;
      }
    }
  }

  & .default-nav {
    display: none;
  }
`;

const HeroImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
  z-index: 1;

  & h2,
  p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h2,
  p,
  a {
    margin: 0;
  }

  & h2 {
    font-size: 32px;
    line-height: 160%;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  & > .text-container {
    position: absolute;
    z-index: 10;

    max-width: 1000px;

    bottom: 0;
    left: 0;

    padding: 60px;
    color: #fff;

    @media (max-width: 1200px) {
      padding: 30px;
    }
    @media (max-width: 768px) {
      padding: 20px 20px 40px 20px;
    }

    & .paragraph-container {
      margin: 15px 0;

      & p {
        font-size: 18px;
        line-height: 177.778%;
        letter-spacing: 0.54px;

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }

  & .link-wrapper {
    margin: 15px 0 0 0;

    & a {
      width: 160px;
      height: 35px;

      text-transform: uppercase;

      position: relative;
      display: block;

      background-color: #fff;
      border: 1px solid #fff;

      font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;
      font-size: 12px;
      letter-spacing: 0.05em;

      color: #000;
      text-align: center;
      line-height: 33px;

      transition: 150ms all ease;
      will-change: all;

      &:hover {
        background-color: transparent;

        color: #fff;
      }

      @media (max-width: 767px) {
        width: 120px;
        font-size: 10px;
      }
    }
  }
`;

const HeroVideoContainer = styled(HeroImageContainer)`
  height: 100%;
  max-height: 700px;
`;

const ImageContainer = styled.div`
  display: block;
  height: 100%;
  width: 100%;

  object-fit: cover;
  max-height: 700px;
`;

const SlideshowArrow = styled.button`
  position: absolute;
  top: 0;
  left: ${props => props.left};
  right: ${props => props.right};

  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 10px;
  height: calc(100% - 41px);

  background: transparent;

  font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;
`;

export const HomepageSlideshow = ({ content }) => {
  const images = content.items.map((content, index) => {
    if (content.image_slide === true) {
      return (
        <HeroImageContainer key={`homepage_slideshow_${index}`}>
          {content.image.gatsbyImageData !== null && (
            <ImageContainer>
              <GatsbyImage
                image={content.image.gatsbyImageData}
                loading={index <= 1 ? `eager` : `lazy`}
              />
            </ImageContainer>
          )}

          <div className="text-container">
            <h2>{content.call_to_action_text.text}</h2>

            {content.text !== undefined && (
              <div
                dangerouslySetInnerHTML={{ __html: content.text.html }}
                className="paragraph-container"
              />
            )}

            {content?.pageLink?.url !== undefined &&
              content?.pageLink?.url !== null && (
                <div className="link-wrapper">
                  <a
                    href={content?.pageLink?.url}
                    target={content?.pageLink?.target}
                  >
                    {content.button_text}
                  </a>
                </div>
              )}
          </div>
        </HeroImageContainer>
      );
    }

    if (content.image_slide === false) {
      return (
        <HeroVideoContainer key={`homepage_slideshow_${index}`}>
          {content.image_slide === false && (
            <SlideshowVideo video={content.video} id={index} />
          )}

          <div className="text-container">
            <h2>{content.call_to_action_text.text}</h2>

            {content.pageLink.url !== undefined &&
              content.pageLink.url !== null && (
                <div className="link-wrapper">
                  <a
                    href={content.pageLink.url}
                    target={content.pageLink.target}
                  >
                    {content.button_text}
                  </a>
                </div>
              )}
          </div>
        </HeroVideoContainer>
      );
    }
  });

  return (
    <SlideshowContainer>
      <Fade indicators={true} autoplay={true} duration={2000} arrows={false}>
        {images}
      </Fade>
    </SlideshowContainer>
  );
};
