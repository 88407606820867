import React from 'react';
import styled from 'styled-components';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';
import { GatsbyImage } from 'gatsby-plugin-image';

const ThreeTwoImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const Image = styled.img`
  padding: ${props => props.padding};

  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const BodyImage = ({ image, padding, marginTop, srcWebp, srcSetWebp }) => {
  return (
    <Container marginTop={marginTop}>
      <Row justifyContent="center">
        <Col col={12} md={11} lg={10} xl={8}>
          <ThreeTwoImageContainer>
            <div>
              <GatsbyImage image={image.gatsbyImageData} loading="lazy" />
            </div>
          </ThreeTwoImageContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default BodyImage;
