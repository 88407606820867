import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';
import { GatsbyImage } from 'gatsby-plugin-image';

const CollectionCard = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  h3 {
    font-size: 18px;
    text-align: center;
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 13px;

    margin: 0;
    color: #323232;
  }
`;

const ProductsContainer = styled.div`
  padding: 0;
`;

const Collections = ({ collections }) => {
  const homepageCollections = collections.map((collection, index) => {
    return (
      <Col col={6} md={3} key={index}>
        <CollectionCard>
          <a
            href={collection?.pageLink?.url}
            target={collection?.pageLink?.target}
          >
            {/* <Link to={'/collections/' + collection.link}> */}
            {collection.image.gatsbyImageData !== null && (
              <GatsbyImage
                image={collection.image.gatsbyImageData}
                alt={collection.image.alt}
                loading="lazy"
              />
            )}
            <h3>{collection.title1.text}</h3>
            {/* </Link> */}
          </a>
        </CollectionCard>
      </Col>
    );
  });

  return (
    <Container>
      <Row justifyContent="center">
        <Col col={12}>
          <ProductsContainer>
            <Row justifyContent="center">{homepageCollections}</Row>
          </ProductsContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Collections;
