import React from 'react';
import styled from 'styled-components';

const EnquireButton = styled.button`
  display: flex;
  flex-direction: row;
`;

const ContactForm = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};

  width: 100%;
  height: auto;

  & p {
    margin: 0 !important;
    padding: 10px 0 2px 0;

    width: 100%;
    max-width: 48%;

    border-bottom: 1px solid #e8e8e8;

    @media (max-width: 500px) {
      max-width: 100%;

      &:first-of-type {
        margin-bottom: 10px !important;
      }
    }
  }

  & form > p {
    border-bottom: 0;
  }

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
  }

  & input {
    width: 100% !important;
    height: 22px;
    position: relative;
    line-height: 1;

    border: 0;
    -webkit-appearance: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  & button {
    width: 160px;
    height: 35px;
    text-transform: uppercase;
    margin: 20px 0 0 0;
    background-color: #934e2a;
    font-size: 13px;

    letter-spacing: 0.9px;
    color: #fff;

    transition: 150ms all ease;
    will-change: all;

    &:hover {
      background-color: rgba(147, 78, 42, 0.8);
    }

    @media (max-width: 500px) {
      width: 100%;
      margin: 30px 0 0 0;
    }
  }
`;

const DownArrow = styled.div`
  box-sizing: border-box;
  height: 6px;
  width: 6px;
  border-style: solid;
  border-color: rgba(50, 50, 50, 0.4);
  border-width: 0px 1px 1px 0px;
  transform: ${props =>
    props.open === true ? 'rotate(-135deg)' : 'rotate(45deg)'};
  transition: transform 15ms linear;

  display: block;
  margin-left: 5px;
  margin-top: 13px;
`;

const Message = styled.div`
  & p {
    margin: 20px 0 0 0 !important;
    font-size: 13px;
  }
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ProductEnquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      name: '',
      email: '',
      formMessage: '',
      isOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      subject: this.props.subjectLine,
    });
  }

  /* Here’s the juicy bit for posting the form submission */

  handleChangeProductEnquiry = e =>
    this.setState({ [e.target.name]: e.target.value });

  handleSubmitProductEnquiry = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'product-enquiry', ...this.state }),
    })
      .then(() => {
        this.setState({
          formMessage: `Thank you for your enquiry. We will be in touch soon.`,
          subject: this.props.subjectLine,
          name: '',
          email: '',
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          formMessage: `There was an error submitting your enquiry.`,
        });
      });

    e.preventDefault();
  };

  toggleProductEnquiry() {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  }

  render() {
    const { subject, name, email } = this.state;

    return (
      <>
        <EnquireButton onClick={() => this.toggleProductEnquiry()}>
          Enquire about stock availability?{' '}
          <DownArrow open={this.state.isOpen} />
        </EnquireButton>

        <ContactForm isOpen={this.state.isOpen}>
          <form
            name="product-enquiry"
            method="post"
            onSubmit={this.handleSubmitProductEnquiry}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input
              type="hidden"
              name="product-enquiry"
              value="product-enquiry"
            />

            <div>
              <p>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={this.handleChangeProductEnquiry}
                  required
                />
              </p>
              <p>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={this.handleChangeProductEnquiry}
                />
              </p>
              <input type="hidden" name="subject" value={subject} />
            </div>

            <button type="submit">ENQUIRE</button>
          </form>
        </ContactForm>

        <Message>
          <p>{this.state.formMessage}</p>
        </Message>
      </>
    );
  }
}

export default ProductEnquiry;
