import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import { StoreContext } from '../context/store-context';
import FormatProductTitle from '../utils/format-product-title';
import ProductEnquiry from '../forms/product-enquiry';
import './select.css';

const PriceQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;

  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif;

  font-feature-settings: 'tnum';

  & p {
    margin: 0 90px 0 0;
  }

  & label {
    text-transform: uppercase;
    margin-right: 30px;
  }

  & input {
    border: 0;
    padding: 0;

    color: #323232;

    width: 30px;
    line-height: 175%;
  }

  & p,
  label,
  input {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }
`;

const AddToCart = styled.button`
  width: 160px;
  height: 35px;

  text-transform: uppercase;

  margin: 0 30px 20px 0;

  background-color: #934e2a;
  font-size: 13px;
  letter-spacing: 0.9px;
  color: #fff;

  transition: 150ms all ease;
  will-change: all;

  &:hover {
    background-color: rgba(147, 78, 42, 0.8);
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 0 30px 0;
  }
`;

const OutOfStock = styled(AddToCart)`
  color: #323232;

  background-color: rgba(232, 232, 232, 0.6);

  &:hover {
    background-color: rgba(232, 232, 232, 0.6);
  }
`;

const FlexPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & span {
    width: 30px;
    text-align: center;
  }
`;

const QuantityToggle = styled.button`
  font-size: 18px;
  font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;

  width: 30px;

  &:hover {
    color: #934e2a;
  }
`;

const TitlePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  & h4 {
    width: 100%;
  }

  & p {
    margin: 0;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;
  }

  @media (max-width: 767px) {
    & h1,
    & p,
    & h4 {
      width: 100%;
    }

    & h1 {
      order: 1;
    }

    & h4 {
      order: 2;
      margin-bottom: 0 !important;
    }

    & p {
      order: 3;
      margin-bottom: 30px;
    }
  }
`;

const OptionsContainer = styled.div`
  & h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    line-height: 1;

    margin: 0 0 15px 0;
  }

  &.fabric-layout {
    margin: 0 0 30px 0;
  }
`;

const Line = styled.hr`
  margin: ${props => props.margin || '30px 0'};

  border: none;
  border-top: 1px solid #e8e8e8;

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '0 0 30px 0'};
  }
`;

const Breadcrumbs = styled.div`
  color: rgba(50, 50, 50, 0.5);

  font-size: 12px;
  line-height: 1;

  margin: 0 0 10px 0;

  & a {
    color: rgba(50, 50, 50, 0.5) !important;
    &:hover {
      color: #934e2a !important;
    }
  }
`;

const SectionNav = styled.div`
  & ol {
    display: flex;
    flex-direction: row;
    list-style: none;

    margin: 0;
    padding: 0;

    & li {
      margin: 0 40px 0 0;

      @media (max-width: 1200px) {
        margin: 0 20px 0 0;
      }

      & button {
        color: #323232;
        font-size: 15px;
        line-height: normal;

        &:hover {
          transition: 250ms color ease;
          color: #934e2a;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    & ol {
      display: block;
      flex-direction: unset;

      & li {
        margin: 0 0 10px 0;
      }
    }
  }

  @media (max-width: 767px) {
    border-bottom: 1px solid #e8e8e8;
    padding: 0 0 20px 0;
  }
`;

const ProductForm = ({
  product,
  breadcrumbUID,
  breadrcrumbCollection,
  variantId,
  quantity,
  available,
  price,
  handleOptionChange,
  setQuantity,
  options,
  pageType,
}) => {
  const { addVariantToCart, loading, adding } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  const addToCart = e => {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
    setAddedToCart(true);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const scrollToSection = section => {
    const element = document.getElementById(section);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const variantOptions = options.map(({ id, name, values }, index) => (
    <div key={`single_variant_option_${index}_${id}`}>
      <select
        name={name}
        key={id}
        onChange={event => handleOptionChange(index, event.target.value)}
        className="select-css"
      >
        {values.map(value => (
          <option
            value={value}
            key={`${name}-${value}`}
            disabled={!available || loading}
          >
            {value}
          </option>
        ))}
      </select>
    </div>
  ));

  const isProductPreOrder =
    product.metafields.length >= 1 &&
    product.metafields[0].key === 'pre-order-product' &&
    product.metafields[0].value === 'True'
      ? true
      : false;

  return (
    <>
      {breadcrumbUID && (
        <Breadcrumbs>
          Collections {'> '}
          <Link to={`/collections/${breadcrumbUID}`}>
            {breadrcrumbCollection}
          </Link>
        </Breadcrumbs>
      )}

      <TitlePriceContainer>
        <h1>{FormatProductTitle(product.title)[0]}</h1>
        <p>{price}</p>
        <h4>{FormatProductTitle(product.title)[1]}</h4>
      </TitlePriceContainer>

      {pageType === 'product' ? (
        <>
          <PriceQuantityContainer>
            {available ? (
              <>
                {isProductPreOrder === true ? (
                  <AddToCart
                    type="submit"
                    disabled={!available || adding}
                    onClick={addToCart}
                  >
                    {adding === true ? 'Added to Basket' : 'Pre-order'}
                  </AddToCart>
                ) : (
                  <AddToCart
                    type="submit"
                    disabled={!available || adding}
                    onClick={addToCart}
                  >
                    {adding === true ? 'Added to Basket' : 'Add to Basket'}
                  </AddToCart>
                )}

                <FlexPriceContainer>
                  <QuantityContainer>
                    Quantity
                    <QuantityToggle onClick={decreaseQuantity}>
                      -
                    </QuantityToggle>
                    <span>{quantity}</span>
                    <QuantityToggle onClick={increaseQuantity}>
                      +
                    </QuantityToggle>
                  </QuantityContainer>
                </FlexPriceContainer>
              </>
            ) : (
              <OutOfStock type="submit" disabled>
                Out Of Stock
              </OutOfStock>
            )}

            {available !== true && (
              <ProductEnquiry subjectLine={product.title} />
            )}
          </PriceQuantityContainer>

          {/*If there's more than one variant, then list it*/}
          {product.options[0].values.length > 1 && (
            <>
              <Line mobileMargin={'30px 0'} />
              <OptionsContainer>
                <h3>Options</h3>
                <div>{variantOptions}</div>
              </OptionsContainer>
            </>
          )}
        </>
      ) : (
        <>
          {/*If there's more than one variant, then list it*/}
          {product.options[0].values.length > 1 && (
            <>
              <Line margin={`0 0 30px 0`} mobileMargin={'30px 0'} />
              <OptionsContainer className="fabric-layout">
                <h3>Options</h3>
                <div>{variantOptions}</div>
              </OptionsContainer>
            </>
          )}

          <PriceQuantityContainer>
            {available ? (
              <>
                {isProductPreOrder === true ? (
                  <AddToCart
                    type="submit"
                    disabled={!available || adding}
                    onClick={addToCart}
                  >
                    {adding === true ? 'Added to Basket' : 'Pre-order'}
                  </AddToCart>
                ) : (
                  <AddToCart
                    type="submit"
                    disabled={!available || adding}
                    onClick={addToCart}
                  >
                    {adding === true ? 'Added to Basket' : 'Add to Basket'}
                  </AddToCart>
                )}

                <FlexPriceContainer>
                  <QuantityContainer>
                    Quantity
                    <QuantityToggle onClick={decreaseQuantity}>
                      -
                    </QuantityToggle>
                    <span>{quantity}</span>
                    <QuantityToggle onClick={increaseQuantity}>
                      +
                    </QuantityToggle>
                  </QuantityContainer>
                </FlexPriceContainer>
              </>
            ) : (
              <OutOfStock type="submit" disabled>
                Out Of Stock
              </OutOfStock>
            )}

            {available !== true && (
              <ProductEnquiry subjectLine={product.title} />
            )}
          </PriceQuantityContainer>

          <Line margin={`10px 0 30px 0`} mobileMargin={'30px 0'} />

          <SectionNav>
            <ol>
              <li>
                <button
                  onClick={() => scrollToSection(`description-and-features`)}
                  type="button"
                >
                  Description & Features
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection(`downloads`)}
                  type="button"
                >
                  Downloads
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection(`specification`)}
                  type="button"
                >
                  Specification
                </button>
              </li>
            </ol>
          </SectionNav>
        </>
      )}
    </>
  );
};

export default ProductForm;
