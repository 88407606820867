import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';
import { useAsyncFn } from 'react-use';

// Icons
import { DownloadIcon } from '../icons/download';

// Components
import { Col } from '../global/col';
import FormatPrice from '../utils/format-price';
import FormatProductTitle from '../utils/format-product-title';
import { TwoThreeImage } from '../images/two-three-image';

const ThreeTwoImage = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProductCard = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & .swatch-download {
    & span {
      margin-left: 10px;
    }
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3,
  & p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h4 {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3,
  h4 {
    font-size: 14px;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 13px;

    margin: 0;
  }
`;

const FabricLink = styled.div`
  width: 160px;
  height: 35px;

  text-transform: uppercase;

  margin: 30px 0 0 0;

  background-color: #444e3e;
  text-align: center;

  transition: 150ms all ease;
  will-change: all;

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 35px;
    text-align: center;

    font-size: 13px;
    letter-spacing: 0.9px;
    color: #fff;
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;
  }

  &:hover {
    background-color: #69785f;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 10px 0 30px 0;

    & a {
      width: 100%;
    }
  }
`;

const Product = ({ data, collection, type }) => {
  const [swatchImage, setSwatchImage] = useState(null);
  const [mediaItem, getMediaItem] = useAsyncFn(async id => {
    const response = await fetch(`/.netlify/functions/get-media-image`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const result = await response.json();
    setSwatchImage(result.node.image.url);
  }, []);

  useEffect(() => {
    if (data.metafield !== null && swatchImage === null && type === `fabric`) {
      getMediaItem(data.metafield.value);
    }
  }, []);

  if (type === `fabric`) {
    return (
      <Col col={12} md={6} lg={4} key={data.handle} order={3}>
        <LazyLoad height={300}>
          <ProductCard>
            <Link
              to={'/products/' + data.handle}
              state={{ collection: collection }}
            >
              <ThreeTwoImage>
                <div>
                  <img
                    src={data.featuredImage.originalSrc}
                    alt={data.alt}
                    loading="eager"
                  />
                </div>
              </ThreeTwoImage>
            </Link>

            <h3>{FormatProductTitle(data.title)[0]}</h3>
            {/* <h4>Available in X colours</h4> */}

            {data.metafield !== null && (
              <a
                className="swatch-download"
                href={swatchImage}
                download
                target="_blank"
              >
                <DownloadIcon /> <span>Download swatch</span>
              </a>
            )}

            <FabricLink>
              <Link
                to={'/products/' + data.handle}
                state={{ collection: collection }}
              >
                View Fabric
              </Link>
            </FabricLink>
          </ProductCard>
        </LazyLoad>
      </Col>
    );
  } else {
    return (
      <Col col={6} md={3} key={data.handle} order={3}>
        <LazyLoad height={700}>
          <ProductCard>
            <Link
              to={'/products/' + data.handle}
              state={{ collection: collection }}
            >
              <TwoThreeImage
                image={data.featuredImage.originalSrc}
                alt={data.title}
              />

              <h3>{FormatProductTitle(data.title)[0]}</h3>
              <h4>{FormatProductTitle(data.title)[1]}</h4>
              <p>
                {/* {data.totalInventory >= 1 ? ( */}
                <>
                  {FormatPrice(
                    data.priceRangeV2.maxVariantPrice.amount,
                    data.priceRangeV2.maxVariantPrice.currencyCode
                  )}
                </>
                {/* // ) : (
              //   <>Out Of Stock</>
              // )} */}
              </p>
            </Link>
          </ProductCard>
        </LazyLoad>
      </Col>
    );
  }
};

export default Product;
