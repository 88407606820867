import React from 'react';
import styled from 'styled-components';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './carousel.css';

const Image = styled.img`
  position: relative;
  cursor: zoom-in;
`;

export const MobileImages = ({ images, alt }) => {
  if (images !== undefined) {
    const productImages = images
      .filter((image, index) => index !== images.length - 1)
      .map((image, index) => {
        // const splitUrl = image.originalSrc.split('.jpg');
        // const startUrl = splitUrl[0] + '_1600x1600.jpg';
        // const endUrl = startUrl + splitUrl[1];

        return (
          <Image
            src={image.image.originalSrc}
            key={image.id}
            alt={alt}
            loading="lazy"
          />
        );
      });

    return (
      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        useKeyboardArrows={true}
      >
        {productImages}
      </Carousel>
    );
  } else {
    return null;
  }
};
