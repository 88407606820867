import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Styles
import '../styles/plyr.css';

const VideoBannerContainer = styled.div`
  position: relative;
  z-index: 10;

  width: 100%;
  height: 100%;
  max-width: 100%;

  & a {
    display: block;
    cursor: pointer;
  }

  & iframe {
    user-select: none;
    pointer-events: none;
  }

  position: relative;
  overflow: hidden;
  /* width: 100vw; */
  /* height: 100vh; */

  & iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  @media (min-aspect-ratio: 16/9) {
    & iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      /* height: 56.25vw !important; */
      height: 166.67vw !important;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    & iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      /* width: 177.78vh !important; */
      width: 60vh !important;
    }
  }
`;

export const SlideshowVideo = ({ id, video }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');

      setPlayer(
        new Plyr(`#slideshow-video-banner${id}`, {
          autoplay: true,
          autopause: true,
          loop: { active: true },
          controls: [],
          hideControls: false,
          clickToPlay: false,
          playsinline: false,
          muted: true,
          volume: 0,
          storage: { enabled: false },
          vimeo: {
            autopause: false,
            background: true,
            byline: false,
            controls: false,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.on('playing', () => {
        setIsPlayerReady(true);
      });
    }
  }, [player]);

  if (video.embed_url !== null) {
    return (
      <VideoBannerContainer>
        <div
          // className="plyr__video-embed"
          // id={`slideshow-video-banner${id}`}
          dangerouslySetInnerHTML={{
            __html: video.html,
          }}
        />
      </VideoBannerContainer>
    );
  } else {
    return null;
  }
};
