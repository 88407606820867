import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import ThreeTwoImage from '../images/three-two-image';

const Card = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3,
  span {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & p {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3 {
    font-size: 18px;
  }

  & .published {
    margin: 5px 0 10px 0;
    font-size: 12px;
    line-height: 12px;
    color: rgba(51, 51, 51, 0.4);

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & a > span {
    font-size: 13px;
    line-height: 100%;

    letter-spacing: 0.04em;

    color: rgba(35, 35, 35, 0.5);

    &:hover {
      color: #934e2a;
    }
  }

  & p {
    color: #323232;
    margin: 5px 0 15px 0;
  }
`;

const JournalCard = ({ data }) => (
  <Card>
    <Link to={'/journal/' + data.uid}>
      {data.data.image.gatsbyImageData !== null && (
        <ThreeTwoImage image={data.data.image} alt={data.data.image.alt} />
      )}
      <h3>{data.data.title.text}</h3>
      <p>{data.data.excerpt}</p>
    </Link>
  </Card>
);

export default JournalCard;
