import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

// Previews
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import Container from '../components/global/container';
import HeroText from '../components/text/hero-text';
import JournalCard from '../components/journal/journal-card';
import { GatsbyImage } from 'gatsby-plugin-image';

const JournalHeroImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;

    object-fit: cover;
    max-height: 560px;
  }
`;

const JournalPage = ({ data }) => {
  const content = data.prismicJournal.data.articles.map((content, index) => (
    <Col col={12} md={6} lg={4} key={content.article.document.id}>
      <JournalCard data={content.article.document} />
    </Col>
  ));

  return (
    <React.Fragment>
      <Helmet
        title={`Journal – Mourne Textiles`}
        meta={[
          {
            name: 'description',
            content: `${data.prismicJournal.data.text.text}`,
          },

          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `Journal – Mourne Textiles`,
          },
          {
            name: 'twitter:description',
            content: `${data.prismicJournal.data.text.text}`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
        ]}
      />

      <JournalHeroImage>
        <GatsbyImage
          image={data.prismicJournal.data.image.gatsbyImageData}
          alt={data.prismicJournal.data.image.alt}
        />
      </JournalHeroImage>

      <HeroText
        text={data.prismicJournal.data.text}
        padding={'70px 0'}
        mobilePadding={'30px 0'}
      />

      <Container marginTop={'0'}>
        <Row>{content}</Row>
      </Container>
    </React.Fragment>
  );
};

export default withPrismicPreview(JournalPage);

export const query = graphql`
  query Journal {
    prismicJournal {
      data {
        text {
          html
          text
        }
        image {
          alt
          gatsbyImageData
        }
        articles {
          article {
            document {
              ... on PrismicJournalArticle {
                id
                uid
                data {
                  excerpt
                  image {
                    alt
                    gatsbyImageData
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
