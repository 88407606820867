import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';
import { GatsbyImage } from 'gatsby-plugin-image';

const CollectionCard = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  h3 {
    font-size: 18px;
    text-align: center;
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 13px;

    margin: 1em 0 0 0;
    color: #323232;
  }
`;

const ProductsContainer = styled.div`
  padding: 0;
`;

const TwoThreeImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 150%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ContractPortraitImages = ({ images }) => {
  const content = images.map((image, index) => {
    return (
      <Col col={6} md={3} key={index}>
        <CollectionCard>
          {image.portrait_image.gatsbyImageData !== null && (
            <>
              <TwoThreeImageContainer>
                <div>
                  <GatsbyImage
                    image={image.portrait_image.gatsbyImageData}
                    alt={image.portrait_image.alt}
                    loading="lazy"
                  />
                </div>
              </TwoThreeImageContainer>
              <p>{image.portrait_image.alt}</p>
            </>
          )}
        </CollectionCard>
      </Col>
    );
  });

  return (
    <Container>
      <Row justifyContent="center">
        <Col col={12}>
          <ProductsContainer>
            <Row justifyContent="center">{content}</Row>
          </ProductsContainer>
        </Col>
      </Row>
    </Container>
  );
};
