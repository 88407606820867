import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

// Context
import { StoreProvider } from './src/components/context/store-context';

// Prismic
import { linkResolver } from './src/components/link-resolver/linkResolver';

// Pages
import Contact from './src/pages/contact';
import Contract from './src/pages/contract';
import Gallery from './src/pages/gallery';
import Index from './src/pages/index';
import Journal from './src/pages/journal';
import OrderConfirmation from './src/pages/order-confirmation';

// Templates
import Collection from './src/templates/collection';
import ContractProject from './src/templates/contract-project';
import FabricCollection from './src/templates/fabric-collection';
import FabricProduct from './src/templates/fabric-product';
import Page from './src/templates/page';
import Product from './src/templates/product';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          contact: Contact,
          contract: Contract,
          gallery: Gallery,
          home: Index,
          journal: Journal,
          order_confirmation: OrderConfirmation,
          collection: Collection,
          contract_project: ContractProject,
          fabric_collection: FabricCollection,
          fabric_product: FabricProduct,
          page: Page,
          product: Product,
        }),
      },
    ]}
  >
    <StoreProvider>{element}</StoreProvider>
  </PrismicPreviewProvider>
);
