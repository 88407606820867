import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

// Previews
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import BodyText from '../components/text/body-text';
import BodyImageCaption from '../components/text/body-image-caption';
import HeroImage from '../components/images/hero-image';
import { ContractSlideshow } from '../components/contract/contract-slideshow';
import { ContractLandscapeImages } from '../components/contract/contract-landscape-images';
import { ContractPortraitImages } from '../components/contract/contract-portrait-images';
import { GatsbyImage } from 'gatsby-plugin-image';

const IntroImageContainer = styled.div`
  margin: 60px 0 0 0;
`;

const Text = styled.div`
  max-width: 660px;
  margin: 0 auto 60px auto;

  width: 100%;

  & p {
    margin: 5px 0 0 0;

    font-size: 12px;
    line-height: 12px;
    color: rgba(51, 51, 51, 0.4);

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }
`;

const ThreeTwoImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const ContractWrapper = styled.div`
  width: 100%;
  margin: 60px auto;

  &:first-of-type {
    margin: 0 auto 60px auto;
  }

  @media (max-width: 767px) {
    margin: 30px auto;

    &:first-of-type {
      margin: 0 auto 30px auto;
    }
  }
`;

const ContractBodyText = styled.div`
  text-align: left;

  max-width: 660px;
  margin: ${props => props.margin || '0'};

  & a {
    position: relative;
    color: #934e2a;
  }

  & p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    & strong {
      margin: 0;
    }
  }

  & h1,
  & h2 {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    margin: ${props => props.mobileMargin || '0'};
    padding: 0 20px;
  }
`;

const ContractProject = ({ data }) => {
  const article = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === 'text') {
      return (
        <ContractWrapper key={`single_contract_text_${index}_${content.id}`}>
          <Row justifyContent="center">
            <Col col={12} md={11} lg={10} xl={8}>
              <ContractBodyText margin={`0`} mobileMargin={`0`}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.primary.text.html,
                  }}
                />
              </ContractBodyText>
            </Col>
          </Row>
        </ContractWrapper>
      );
    }

    if (content.slice_type === 'slideshow') {
      return (
        <ContractWrapper key={`single_slideshow_${content.id}`}>
          <ContractSlideshow content={content} />
        </ContractWrapper>
      );
    }

    if (content.slice_type === `landscape_images`) {
      return (
        <ContractWrapper key={`single_contract_images_${index}_${content.id}`}>
          <Row justifyContent="center">
            <Col col={12} md={11} lg={10} xl={8}>
              <ContractLandscapeImages data={content.items} />
            </Col>
          </Row>
        </ContractWrapper>
      );
    }

    if (content.slice_type === `portrait_images`) {
      return (
        <ContractWrapper
          key={`single_contract_portrait_images_${index}_${content.id}`}
        >
          <Row justifyContent="center">
            <Col col={12} md={11} lg={10} xl={8}>
              <ContractPortraitImages images={content.items} />
            </Col>
          </Row>
        </ContractWrapper>
      );
    }

    if (content.slice_type === 'image') {
      return (
        <ContractWrapper key={`single_contract_image_${index}_${content.id}`}>
          <Row justifyContent="center">
            <Col col={12} md={11} lg={10} xl={8}>
              {content.primary.article_image.gatsbyImageData !== null && (
                <ThreeTwoImageContainer>
                  <div>
                    <GatsbyImage
                      image={content.primary.article_image.gatsbyImageData}
                      loading="lazy"
                    />
                  </div>
                </ThreeTwoImageContainer>
              )}
              {content.primary.caption && (
                <BodyImageCaption text={content.primary.caption} />
              )}
            </Col>
          </Row>
        </ContractWrapper>
      );
    }

    if (content.slice_type === 'full_width_image') {
      return (
        <ContractWrapper key={`hero_image_${content.id}`}>
          <React.Fragment>
            {content.primary.image.gatsbyImageData !== null && (
              <HeroImage
                alt={content.primary.image.alt}
                image={content.primary.image.gatsbyImageData}
                callToAction={null}
                buttonText={null}
                link={null}
              />
            )}
          </React.Fragment>
        </ContractWrapper>
      );
    }
  });

  return (
    <>
      {data.prismicProject.data.image.gatsbyImageData !== null && (
        <IntroImageContainer>
          <Row justifyContent="center">
            <Col col={12} md={11} lg={10} xl={8}>
              {data.prismicProject.data.image.gatsbyImageData !== null && (
                <ThreeTwoImageContainer>
                  <div>
                    <GatsbyImage
                      image={data.prismicProject.data.image.gatsbyImageData}
                      alt={data.prismicProject.data.image.alt}
                      loading="lazy"
                    />
                  </div>
                </ThreeTwoImageContainer>
              )}
            </Col>
          </Row>
        </IntroImageContainer>
      )}
      <ContractWrapper>
        <Row justifyContent="center">
          <Col col={12} md={11} lg={10} xl={8}>
            <ContractBodyText margin={`0 0 0 0`} mobileMargin={`0 0 0 0`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.prismicProject.data.title.html,
                }}
              />

              {data.prismicProject.data.excerpt !== null && (
                <Text>
                  <p>{data.prismicProject.data.excerpt}</p>
                </Text>
              )}
            </ContractBodyText>
          </Col>
        </Row>
      </ContractWrapper>

      <div>{article}</div>
    </>
  );
};

export default withPrismicPreview(ContractProject);

export const ContractProjectQuery = graphql`
  query ContractProjectQuery($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicProjectDataBodyImage {
            id
            slice_type
            primary {
              article_image {
                url
                alt
                gatsbyImageData
              }
              caption
            }
          }
          ... on PrismicProjectDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectDataBodySlideshow {
            id
            slice_type
            items {
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicProjectDataBodyFullWidthVideo {
            id
            slice_type
            primary {
              video {
                html
                width
                height
                embed_url
              }
              call_to_action_text {
                html
              }
              link
              button_text
            }
          }
          ... on PrismicProjectDataBodyLandscapeImages {
            id
            slice_type
            items {
              full_width_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicProjectDataBodyPortraitImages {
            slice_type
            id
            items {
              portrait_image {
                alt
                dimensions {
                  width
                  height
                }
                gatsbyImageData
              }
            }
          }
          ... on PrismicProjectDataBodyFullWidthImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData
              }
            }
          }
        }
        image {
          url
          alt
          gatsbyImageData
        }
        title {
          html
        }
        excerpt
        date(formatString: "MMMM ‘YY")
      }
    }
  }
`;
