import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// import { Carousel } from 'react-responsive-carousel';
// import '../product/carousel.css';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { GatsbyImage } from 'gatsby-plugin-image';

const SlideshowContainer = styled.div`
  position: relative;

  & .indicators {
    position: absolute;

    bottom: 15px;
    left: 0;
    right: 0;

    margin-top: 0;
    padding: 0;
    margin: 0;

    z-index: 10;

    & > li {
      margin: 0 4px !important;

      & .each-slideshow-indicator:before {
        background: #fff;
      }
    }
  }

  & .default-nav {
    display: none;
  }
`;

const HeroImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;

  & h2,
  p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h2,
  p,
  a {
    margin: 0;
  }

  & h2 {
    line-height: 160%;
  }

  // &:before {
  //   content: '';
  //   display: block;

  //   width: 100%;
  //   height: 100%;

  //   background: linear-gradient(
  //     180deg,
  //     rgba(50, 50, 50, 0) 0%,
  //     rgba(35, 35, 35, 0.34) 100%
  //   );

  //   position: absolute;

  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }

  & div {
    position: absolute;

    max-width: 700px;

    bottom: 0;
    left: 0;

    padding: 60px;
    @media (max-width: 1200px) {
      padding: 30px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }

    color: #fff;
  }

  & a {
    width: 100%;
    height: 100%;

    position: relative;
    display: block;

    color: #000;

    // text-transform: uppercase;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    font-size: 14px;
    letter-spacing: 0.05em;

    &:hover img {
      opacity: 1;
    }
  }

  & button {
    width: 160px;
    height: 35px;

    text-transform: uppercase;

    margin: 15px 0 0 0;

    background-color: #fff;
    border: 1px solid #fff;
    font-size: 13px;
    letter-spacing: 0.9px;
    color: #000;

    transition: 150ms all ease;
    will-change: all;

    &:hover {
      background-color: transparent;

      & a {
        color: #fff;
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: block;
  width: 100%;

  object-fit: cover;
  max-height: 700px;
`;

const SlideshowArrow = styled.button`
  position: absolute;
  top: 0;
  left: ${props => props.left};
  right: ${props => props.right};

  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 10px;
  height: calc(100% - 41px);

  background: transparent;

  font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;
`;

export const ContractSlideshow = ({ content }) => {
  const images = content.items.map((content, index) => (
    <HeroImageContainer key={`homepage_slideshow_${index}`}>
      {content.image.gatsbyImageData !== null && (
        <ImageContainer>
          <GatsbyImage image={content.image.gatsbyImageData} loading="lazy" />
        </ImageContainer>
      )}
      {/* <div>
        <h2>{content.call_to_action_text.text}</h2>

        {content.link !== undefined && content.link !== null && (
          <button>
            <Link to={`/${content.link}`}>{content.button_text}</Link>
          </button>
        )}
      </div> */}
    </HeroImageContainer>
  ));

  return (
    <SlideshowContainer>
      <Fade indicators={true} autoplay={true} duration={2000}>
        {images}
      </Fade>
    </SlideshowContainer>
  );
};
