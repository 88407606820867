exports.linkResolver = doc => {
  if (doc.type === 'home') {
    return `/`;
  }

  if (doc.type === 'contact_us') {
    return `/contact`;
  }

  if (doc.type === 'gallery') {
    return `/gallery`;
  }

  if (doc.type === 'journal') {
    return `/journal`;
  }

  if (doc.type === 'journal_article') {
    return `/journal/${doc.uid}`;
  }

  if (doc.type === 'project') {
    return `/contract/${doc.uid}`;
  }

  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }

  if (doc.type === 'contract') {
    return `/${doc.uid}`;
  }

  // Backup for all other types
  return `/${doc.uid}/`;
};
