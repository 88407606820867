import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import isEqual from 'lodash.isequal';
import { useAsyncFn } from 'react-use';

// Convert Shopify metafield rich text output
import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer';

// Context
import { StoreContext } from '../components/context/store-context';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';

import { MobileImages } from '../components/product/mobile-images';
import { DesktopImages } from '../components/product/desktop-images';
import { TwoThreeImage } from '../components/images/two-three-image';

import Container from '../components/global/container';
import ProductForm from '../components/product/product-form';
import FormatPrice from '../components/utils/format-price';
import FormatProductTitle from '../components/utils/format-product-title';
import { FabricGallery } from '../components/product/fabric-gallery';

// Hooks
import { useMediaQuery } from '../components/hooks/useMediaQuery';

const Line = styled.hr`
  margin: 30px 0 30px 0;
  border: none;
  border-top: 1px solid #e8e8e8;

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '0 0 30px 0'};
  }
`;

const ProductCard = styled.div`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3,
  & p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h4 {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3,
  h4 {
    font-size: 12px;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 12px;

    margin: 0;
    color: #323232;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  flex: 1;

  & h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    line-height: 150%;

    margin: 0;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    margin-bottom: 30px;
  }

  & p[style] {
    line-height: 24px !important;
    margin: inherit !important;
  }

  // & .control-dots {
  //   position: absolute;
  // }
`;

const Column = styled.div`
  position: relative;
  overflow: hidden;

  padding: 30px 0 0 0;

  @media (max-width: 1200px) {
    padding: 30px 0 0 30px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }

  flex: 0 0 40%;
  max-width: 40%;

  & .carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-height: calc(100vh - 139px);

    & .slider-wrapper {
      order: 1;
    }

    & .control-dots {
      order: 12;
    }

    & img {
      max-height: calc(100vh - 181px);
      width: 100%;

      object-fit: contain;
      object-position: center;

      @media (max-width: 1000px) {
        max-height: calc(100vh - 80px);
      }

      @media (max-width: 767px) {
        object-position: center;
        max-height: 100%;
        padding: 20px;
      }
    }
  }

  @media (max-width: 1000px) {
    flex: 0 0 50%;
    max-width: 700px;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 100%;
  }
`;

const TextColumn = styled.div`
  /* flex: 0 0 50%;
  max-width: 50%;*/

  @media (max-width: 1000px) {
    flex: 0 0 50%;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const TextColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 650px;
  width: 100%;

  padding: 0 60px;

  span[style] {
    color: inherit !important;
  }

  & a {
    position: relative;
    color: #934e2a;
  }

  @media (max-width: 1200px) {
    padding: 0 30px;
    max-width: 500px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding-top: 0;
    max-width: 100%;
  }
`;

const Sticky = styled.div`
  position: sticky;
  margin: 120px 0 40px 0;
  top: 60px;

  display: flex;
  justify-content: flex-start;

  @media (max-width: 767px) {
    position: relative;
    margin-top: 0;
  }
`;

const SwatchesContainer = styled.div`
  & h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    line-height: 1;

    margin: 0 0 15px 0;
  }
`;

const Swatches = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;

  @media (max-width: 768px) {
    grid-gap: 20px;
  }

  @media (max-width: 500px) {
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Swatch = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;

  /* & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 150px;
  } */
`;

const FabricContainer = styled.div`
  padding: 0 60px;

  @media (max-width: 1200px) {
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  & #colors-container {
    margin: 120px 0 0 0;

    & h2 {
      margin: 0 0 20px 0;
    }

    @media (max-width: 767px) {
      margin: 50px 0 0 0;
    }
  }

  & #description-and-features {
    & .description {
      max-width: 740px;

      @media (max-width: 767px) {
        max-width: 100%;
        border-bottom: 1px solid #e8e8e8;
        margin: 0 0 30px 0;
      }
    }

    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;

      @media (max-width: 1200px) {
        display: block;
        grid-template-columns: unset;
        column-gap: unset;
      }
    }

    & strong {
      margin-top: 0;
    }

    & .downloads-grid {
      display: grid;
      grid-template-columns: 260px 1fr;
      column-gap: 60px;

      @media (max-width: 800px) {
        display: block;
        grid-template-columns: unset;
        column-gap: unset;
      }
    }

    & .downloadable-grid > p {
      display: grid;
      grid-template-columns: 260px 1fr;
      column-gap: 60px;

      @media (max-width: 800px) {
        display: block;
        grid-template-columns: unset;
        column-gap: unset;
      }
    }
  }

  & #downloads {
    & p {
      display: grid;
      grid-template-columns: 360px 1fr;
      column-gap: 60px;

      @media (max-width: 800px) {
        display: block;
        grid-template-columns: unset;
        column-gap: unset;
      }

      & strong {
        font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
          'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue',
          'Lucida Grande', sans-serif !important;

        margin: 0;
        color: rgba(50, 50, 50, 0.5);
      }
    }
  }

  & #specification {
    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;

      @media (max-width: 1200px) {
        display: block;
        grid-template-columns: unset;
        column-gap: unset;
      }

      & p {
        display: grid;
        grid-template-columns: 150px 1fr;
        column-gap: 60px;

        @media (max-width: 1200px) {
          grid-template-columns: 260px 1fr;
        }

        @media (max-width: 800px) {
          display: block;
          grid-template-columns: unset;
          column-gap: unset;
        }

        & strong {
          font-family: 'Garnett Light', system, -apple-system,
            '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI',
            'Helvetica Neue', 'Lucida Grande', sans-serif !important;

          margin: 0;
          color: rgba(50, 50, 50, 0.5);
        }
      }
    }
  }
`;

const FabricProductPage = ({ data }) => {
  // Media Query
  let isDesktop = useMediaQuery('(min-width: 768px)');

  const [downloadableSwatch, setDownloadableSwatch] = useState(null);

  // render() {
  const product = data.shopifyProduct;
  let swatches;

  const productTags = product.tags;

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
  } = product;
  const { client } = useContext(StoreContext);

  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  const [hasVariantChanged, setHasVariantChanged] = useState(false);
  const handleOptionChange = (index, value) => {
    setHasVariantChanged(true);

    if (value === '') {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  const [galleryItems, setGalleryItems] = useState([]);

  const [mediaItem, getMediaItem] = useAsyncFn(async id => {
    const response = await fetch(`/.netlify/functions/get-media-image`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const result = await response.json();
    return result.node.image;
  }, []);

  const gallery = product.metafields.filter(field => field.key === 'gallery');
  const galleryImages = gallery.length >= 1 ? JSON.parse(gallery[0].value) : [];

  useEffect(() => {
    if (galleryItems.length !== galleryImages.length) {
      galleryImages.forEach(async item => {
        const mediaItem = await getMediaItem(item);
        setGalleryItems(prevItems => [...prevItems, mediaItem]);
      });
    }
  }, []);

  if (data.allShopifyProduct === null) {
    swatches = null;
  } else {
    swatches = data.allShopifyProduct.edges
      .filter(product =>
        //https://stackoverflow.com/questions/31835447/check-if-one-array-is-contained-in-another-array
        product.node.tags.every(item => productTags.indexOf(item) !== -1)
      )
      .filter(product => product.node !== undefined)
      .filter(product => product.node.handle !== data.shopifyProduct.handle)
      .filter(product => product.node.media.length >= 1)
      .filter(product => product.node.media[0] !== undefined)
      .map(product => {
        const swatchImage = product.node.media[0].image.originalSrc;

        return (
          <LazyLoad height={30} key={product.node.handle}>
            <Swatch key={product.node.handle}>
              <Link to={'/products/' + product.node.handle}>
                <TwoThreeImage image={swatchImage} alt={product.node.title} />
              </Link>
            </Swatch>
          </LazyLoad>
        );
      });
  }

  let seoImage;
  if (product.images !== null && product.images !== undefined) {
    seoImage = product.images[0].originalSrc;
  } else {
    seoImage = null;
  }

  const breadrcrumbCollection =
    data.allShopifyCollection.edges.length >= 1
      ? data.allShopifyCollection.edges[0].node.title
      : '';

  const breadcrumbUID =
    data.allShopifyCollection.edges.length >= 1
      ? data.allShopifyCollection.edges[0].node.handle
      : '';

  const schemaImages =
    product.featuredImage !== null ? product.featuredImage.originalSrc : '';

  const productSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: `${product.title}`,
    image: schemaImages,
    description: `${product.description}`,
    sku: `${product.variants[0].sku}`,
    brand: {
      '@type': 'Brand',
      name: 'Mourne Textiles',
    },
    offers: {
      '@type': 'Offer',
      url: `https://www.mournetextiles.com/products/${product.handle}`,
      priceCurrency: 'GBP',
      price: `${product.variants[0].price}`,
    },
  };

  const specifications = product.metafields.filter(
    field => field.key === 'specifications'
  );

  const downloads = product.metafields.filter(
    field => field.key === 'downloads'
  );

  const swatchDownload = product.metafields.filter(
    field => field.key === 'swatch'
  );

  useEffect(() => {
    if (
      swatchDownload.length >= 1 &&
      swatchDownload[0].value !== null &&
      downloadableSwatch === null
    ) {
      swatchDownload.forEach(async item => {
        const mediaItem = await getMediaItem(item.value);
        setDownloadableSwatch(mediaItem);
      });
    }
  }, []);

  return (
    <>
      <Helmet
        title={`${
          product.seo.title !== null
            ? `${product.seo.title} – Mourne Textiles`
            : `${product.title} – Mourne Textiles`
        }`}
        meta={[
          {
            name: 'description',
            content: `${
              product.seo.description !== null
                ? product.seo.description
                : product.description
            }`,
          },
          {
            name: 'og:image',
            content: `${seoImage}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${seoImage}`,
          },
          {
            name: 'og:description',
            content: `${
              product.seo.description !== null
                ? product.seo.description
                : product.description
            }`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'og:title',
            content: `${
              product.seo.title !== null
                ? `${product.seo.title} – Mourne Textiles`
                : `${product.title} – Mourne Textiles`
            }`,
          },
          {
            name: 'twitter:title',
            content: `${
              product.seo.title !== null
                ? `${product.seo.title} – Mourne Textiles`
                : `${product.title} – Mourne Textiles`
            }`,
          },
          {
            name: 'twitter:description',
            content: `${
              product.seo.description !== null
                ? product.seo.description
                : product.description
            }`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${seoImage}`,
          },
        ]}
      />
      <Helmet encodeSpecialCharacters={false}>
        <script type="application/ld+json">
          {JSON.stringify(productSchema)}
        </script>
      </Helmet>

      <ProductContainer>
        <Column>
          {isDesktop ? (
            <DesktopImages images={product.media} alt={product.title} />
          ) : (
            <MobileImages images={product.media} alt={product.title} />
          )}
        </Column>

        <TextColumn>
          <Sticky>
            <TextColumnInner>
              <ProductForm
                product={product}
                breadcrumbUID={breadcrumbUID}
                breadrcrumbCollection={breadrcrumbCollection}
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
                price={new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(productVariant.price)}
                handleOptionChange={handleOptionChange}
                setQuantity={setQuantity}
                options={options}
                pageType={`fabric`}
              />
            </TextColumnInner>
          </Sticky>
        </TextColumn>
      </ProductContainer>

      <FabricContainer>
        <section id="colors-container">
          <h2>Colours</h2>
          <div className="grid">
            {swatches !== null && swatches.length >= 1 && (
              <>
                <SwatchesContainer>
                  <Swatches>{swatches}</Swatches>
                </SwatchesContainer>
              </>
            )}
          </div>
        </section>

        <Line mobileMargin={'30px 0'} />

        <section className="text-container" id="description-and-features">
          <div className="grid">
            <div className="column left">
              <h2>Description & Features</h2>

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </div>

            <div className="column right" id="downloads">
              <h2>Downloads</h2>

              <p className="downloads-grid">
                {downloadableSwatch !== null && (
                  <>
                    <strong>Swatch Card</strong>
                    <a href={downloadableSwatch.url} target="_blank" download>
                      Download
                    </a>
                  </>
                )}
              </p>

              {downloads.length >= 1 && (
                <div
                  className="downloadable-grid"
                  dangerouslySetInnerHTML={{
                    __html: convertSchemaToHtml(downloads[0].value),
                  }}
                />
              )}
            </div>
          </div>
        </section>

        <Line mobileMargin={'30px 0'} />

        {specifications.length >= 1 && (
          <section className="text-container" id="specification">
            <h2>Specification</h2>

            <div
              className="grid"
              dangerouslySetInnerHTML={{
                __html: convertSchemaToHtml(specifications[0].value),
              }}
            />
            <Line mobileMargin={'30px 0'} />
          </section>
        )}

        {galleryItems.length > 0 && (
          <>
            {galleryItems.length === galleryImages.length && (
              <section className="image-gallery-container">
                <FabricGallery images={galleryItems} />
              </section>
            )}
          </>
        )}
      </FabricContainer>
    </>
  );
};

export const query = graphql`
  query($handle: String!, $tags: [String!], $productType: String!) {
    allShopifyProduct(
      filter: { tags: { in: $tags }, productType: { eq: $productType } }
      sort: { title: ASC }
    ) {
      edges {
        node {
          title
          tags
          handle
          variants {
            price
          }
          featuredImage {
            originalSrc
            altText
          }
          media {
            ... on ShopifyMediaImage {
              id
              image {
                originalSrc
              }
              mediaContentType
            }
          }
        }
      }
    }
    allShopifyCollection(
      filter: { products: { elemMatch: { handle: { eq: $handle } } } }
    ) {
      edges {
        node {
          title
          handle
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      descriptionHtml
      description
      shopifyId
      seo {
        description
        title
      }
      options {
        shopifyId
        name
        values
      }
      featuredImage {
        originalSrc
        altText
      }
      media {
        ... on ShopifyMediaImage {
          id
          image {
            originalSrc
          }
          mediaContentType
        }
      }
      metafields {
        value
        namespace
        key
      }
      storefrontId
      variants {
        id
        title
        price
        availableForSale
        storefrontId
        shopifyId
        sku
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      tags
    }
  }
`;

export default FabricProductPage;
