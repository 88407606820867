import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import isEqual from 'lodash.isequal';

// Context
import { StoreContext } from '../components/context/store-context';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';

import { MobileImages } from '../components/product/mobile-images';
import { DesktopImages } from '../components/product/desktop-images';
import { TwoThreeImage } from '../components/images/two-three-image';

import Container from '../components/global/container';
import ProductForm from '../components/product/product-form';
import FormatPrice from '../components/utils/format-price';
import FormatProductTitle from '../components/utils/format-product-title';

// Hooks
import { useMediaQuery } from '../components/hooks/useMediaQuery';

const Line = styled.hr`
  margin: 30px 0 30px 0;
  border: none;
  border-top: 1px solid #e8e8e8;

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '0 0 30px 0'};
  }
`;

const ProductCard = styled.div`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3,
  & p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h4 {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3,
  h4 {
    font-size: 12px;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 12px;

    margin: 0;
    color: #323232;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  flex: 1;

  & h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    line-height: 150%;

    margin: 0;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    margin-bottom: 30px;
  }

  & p[style] {
    line-height: 24px !important;
    margin: inherit !important;
  }

  // & .control-dots {
  //   position: absolute;
  // }
`;

const Column = styled.div`
  position: relative;
  overflow: hidden;

  padding: 30px 0 0 60px;

  @media (max-width: 1200px) {
    padding: 30px 0 0 30px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }

  flex: 0 0 50%;
  max-width: 50%;

  & .carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-height: calc(100vh - 139px);

    & .slider-wrapper {
      order: 1;
    }

    & .control-dots {
      order: 12;
    }

    & img {
      max-height: calc(100vh - 181px);
      width: 100%;

      object-fit: contain;
      object-position: center;

      @media (max-width: 1000px) {
        max-height: calc(100vh - 80px);
      }

      @media (max-width: 767px) {
        object-position: center;
        max-height: 100%;
        padding: 20px;
      }
    }
  }

  @media (max-width: 1000px) {
    flex: 0 0 50%;
    max-width: 700px;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 100%;
  }
`;

const TextColumn = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  @media (max-width: 1000px) {
    flex: 0 0 50%;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 100%;
  }
`;

const TextColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 650px;

  padding: 0 60px;

  span[style] {
    color: inherit !important;
  }

  & a {
    position: relative;
    color: #934e2a;
  }

  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const Sticky = styled.div`
  margin-top: 60px;

  display: flex;
  justify-content: flex-start;

  @media (max-width: 767px) {
    position: relative;
    margin-top: 0;
  }
`;

const RelatedProducts = styled.div``;

const RelatedProductsTitle = styled.h4`
  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;

  text-align: center;
  margin-bottom: 30px;
`;

const SwatchesContainer = styled.div`
  & h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    line-height: 1;

    margin: 0 0 15px 0;
  }
`;

const Swatches = styled.ul`
  margin: 0 -5px;
  padding: 0;

  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Swatch = styled.li`
  width: 30px;
  height: 30px;

  margin: 5px;
  padding: 0;
  overflow: hidden;

  position: relative;

  border-radius: 100px;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 150px;
  }
`;

const ProductPage = ({ data }) => {
  // Media Query
  let isDesktop = useMediaQuery('(min-width: 768px)');

  // render() {
  const product = data.shopifyProduct;
  let allRelatedProducts;
  let swatches;

  const productTags = product.tags;

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
  } = product;
  const { client } = useContext(StoreContext);

  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  const [hasVariantChanged, setHasVariantChanged] = useState(false);
  const handleOptionChange = (index, value) => {
    setHasVariantChanged(true);

    if (value === '') {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };
  // const handleOptionChange = (index, value) => {
  //   setHasVariantChanged(true);

  //   if (value === '') {
  //     return;
  //   }

  //   const currentOptions = [...variant.selectedOptions];

  //   currentOptions[index] = {
  //     ...currentOptions[index],
  //     value,
  //   };

  //   const selectedVariant = variants.find(variant => {
  //     return isEqual(currentOptions, variant.selectedOptions);
  //   });

  //   setVariant({ ...selectedVariant });
  // };

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  const hasVariants = variants.length > 1;

  if (data.allShopifyProduct === null) {
    allRelatedProducts = null;
    swatches = null;
  } else {
    allRelatedProducts = data.allShopifyProduct.edges
      .filter(product =>
        //https://stackoverflow.com/questions/31835447/check-if-one-array-is-contained-in-another-array
        product.node.tags.every(item => productTags.indexOf(item) !== -1)
      )
      .filter(product => product.node.title !== data.shopifyProduct.title)
      .filter(product => product.node !== undefined)
      .map(product => {
        return (
          <Col col={6} md={3} lg={2} key={product.node.handle}>
            <LazyLoad height={400}>
              <ProductCard>
                <Link to={'/products/' + product.node.handle}>
                  <img
                    src={product.node.featuredImage.originalSrc}
                    // alt={product.node.images[0].alt}
                  />
                  <h3>{FormatProductTitle(product.node.title)[0]}</h3>
                  <h4>{FormatProductTitle(product.node.title)[1]}</h4>
                  <p>{FormatPrice(product.node.variants[0].price, 'gbp')}</p>
                </Link>
              </ProductCard>
            </LazyLoad>
          </Col>
        );
      });

    swatches = data.allShopifyProduct.edges
      .filter(product =>
        //https://stackoverflow.com/questions/31835447/check-if-one-array-is-contained-in-another-array
        product.node.tags.every(item => productTags.indexOf(item) !== -1)
      )
      .filter(product => product.node !== undefined)
      .filter(product => product.node.media.length >= 1)
      .filter(product => product.node.media[0] !== undefined)
      .map(product => {
        // Last image in gallery
        const swatchImageLocation = product.node.media.length - 1;
        const swatchImage =
          product.node.media[swatchImageLocation].image.originalSrc;

        return (
          <LazyLoad height={30} key={product.node.handle}>
            <Swatch key={product.node.handle}>
              <Link to={'/products/' + product.node.handle}>
                <TwoThreeImage image={swatchImage} alt={product.node.title} />
              </Link>
            </Swatch>
          </LazyLoad>
        );
      });
  }

  let seoImage;
  if (product.images !== null && product.images !== undefined) {
    seoImage = product.images[0].originalSrc;
  } else {
    seoImage = null;
  }

  const breadrcrumbCollection =
    data.allShopifyCollection.edges.length >= 1
      ? data.allShopifyCollection.edges[0].node.title
      : '';

  const breadcrumbUID =
    data.allShopifyCollection.edges.length >= 1
      ? data.allShopifyCollection.edges[0].node.handle
      : '';

  const schemaImages =
    product.featuredImage !== null ? product.featuredImage.originalSrc : '';

  const productSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: `${product.title}`,
    image: schemaImages,
    description: `${product.description}`,
    sku: `${product.variants[0].sku}`,
    brand: {
      '@type': 'Brand',
      name: 'Mourne Textiles',
    },
    offers: {
      '@type': 'Offer',
      url: `https://www.mournetextiles.com/products/${product.handle}`,
      priceCurrency: 'GBP',
      price: `${product.variants[0].price}`,
    },
  };

  return (
    <>
      <Helmet
        title={`${
          product.seo.title !== null
            ? `${product.seo.title} – Mourne Textiles`
            : `${product.title} – Mourne Textiles`
        }`}
        meta={[
          {
            name: 'description',
            content: `${
              product.seo.description !== null
                ? product.seo.description
                : product.description
            }`,
          },
          {
            name: 'og:image',
            content: `${seoImage}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${seoImage}`,
          },
          {
            name: 'og:description',
            content: `${
              product.seo.description !== null
                ? product.seo.description
                : product.description
            }`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'og:title',
            content: `${
              product.seo.title !== null
                ? `${product.seo.title} – Mourne Textiles`
                : `${product.title} – Mourne Textiles`
            }`,
          },
          {
            name: 'twitter:title',
            content: `${
              product.seo.title !== null
                ? `${product.seo.title} – Mourne Textiles`
                : `${product.title} – Mourne Textiles`
            }`,
          },
          {
            name: 'twitter:description',
            content: `${
              product.seo.description !== null
                ? product.seo.description
                : product.description
            }`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${seoImage}`,
          },
        ]}
      />
      <Helmet encodeSpecialCharacters={false}>
        <script type="application/ld+json">
          {JSON.stringify(productSchema)}
        </script>
      </Helmet>

      <ProductContainer>
        <Column>
          {isDesktop ? (
            <DesktopImages images={product.media} alt={product.title} />
          ) : (
            <MobileImages images={product.media} alt={product.title} />
          )}
        </Column>
        <TextColumn>
          <Sticky>
            <TextColumnInner>
              <ProductForm
                product={product}
                breadcrumbUID={breadcrumbUID}
                breadrcrumbCollection={breadrcrumbCollection}
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
                price={new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(productVariant.price)}
                handleOptionChange={handleOptionChange}
                setQuantity={setQuantity}
                options={options}
                pageType={`product`}
              />

              {swatches !== null && swatches.length >= 1 && (
                <>
                  <Line mobileMargin={'30px 0'} />
                  <SwatchesContainer>
                    <h3>Colours</h3>
                    <Swatches>{swatches}</Swatches>
                  </SwatchesContainer>
                  <Line mobileMargin={'30px 0'} />
                </>
              )}

              <div
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </TextColumnInner>
          </Sticky>
        </TextColumn>
      </ProductContainer>

      {allRelatedProducts !== null && allRelatedProducts.length >= 1 && (
        <RelatedProducts>
          <Container>
            <Row>
              <Col col={12}>
                <Line />
                <RelatedProductsTitle>Related Products</RelatedProductsTitle>
              </Col>
              {allRelatedProducts}
            </Row>
          </Container>
        </RelatedProducts>
      )}
    </>
  );
};

export const query = graphql`
  query($handle: String!, $tags: [String!], $productType: String!) {
    allShopifyProduct(
      filter: { tags: { in: $tags }, productType: { eq: $productType } }
      sort: { title: ASC }
    ) {
      edges {
        node {
          title
          tags
          handle
          variants {
            price
          }
          featuredImage {
            originalSrc
            altText
          }
          media {
            ... on ShopifyMediaImage {
              id
              image {
                originalSrc
              }
              mediaContentType
            }
          }
        }
      }
    }
    allShopifyCollection(
      filter: { products: { elemMatch: { handle: { eq: $handle } } } }
    ) {
      edges {
        node {
          title
          handle
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      descriptionHtml
      description
      shopifyId
      seo {
        description
        title
      }
      options {
        shopifyId
        name
        values
      }
      featuredImage {
        originalSrc
        altText
      }
      media {
        ... on ShopifyMediaImage {
          id
          image {
            originalSrc
          }
          mediaContentType
        }
      }
      metafields {
        value
        namespace
        key
      }
      storefrontId
      variants {
        id
        title
        price
        availableForSale
        storefrontId
        shopifyId
        sku
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      tags
    }
  }
`;

export default ProductPage;
