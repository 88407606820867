import React from 'react';

export const DownloadIcon = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.95449 0.5C5.23064 0.5 5.45449 0.723858 5.45449 1V6.79289L7.78292 4.46447C7.97818 4.2692 8.29477 4.2692 8.49003 4.46447C8.68529 4.65973 8.68529 4.97631 8.49003 5.17157L5.30805 8.35355C5.11279 8.54882 4.7962 8.54882 4.60094 8.35355L1.41896 5.17157C1.2237 4.97631 1.2237 4.65973 1.41896 4.46447C1.61422 4.2692 1.93081 4.2692 2.12607 4.46447L4.45449 6.79289L4.45449 1C4.45449 0.723858 4.67835 0.5 4.95449 0.5ZM0.480469 10.5C0.480469 10.2239 0.704326 10 0.980469 10H8.92852C9.20466 10 9.42852 10.2239 9.42852 10.5C9.42852 10.7761 9.20466 11 8.92852 11H0.980469C0.704326 11 0.480469 10.7761 0.480469 10.5Z"
        fill="#232323"
      />
    </svg>
  );
};
