import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;

  & h2,
  p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h2,
  p,
  a {
    margin: 0;
  }

  & h2 {
    line-height: 160%;
  }

  &:before {
    content: '';
    display: block;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(50, 50, 50, 0) 0%,
      rgba(35, 35, 35, 0.34) 100%
    );

    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & div {
    position: absolute;

    max-width: 700px;

    bottom: 0;
    left: 0;

    padding: 60px;
    @media (max-width: 1200px) {
      padding: 30px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }

    color: #fff;
  }

  & a {
    width: 100%;
    height: 100%;

    position: relative;
    display: block;

    color: #000;

    // text-transform: uppercase;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    font-size: 14px;
    letter-spacing: 0.05em;

    &:hover img {
      opacity: 1;
    }
  }

  & button {
    width: 160px;
    height: 35px;

    text-transform: uppercase;

    margin: 15px 0 0 0;

    background-color: #fff;
    border: 1px solid #fff;
    font-size: 13px;
    letter-spacing: 0.9px;
    color: #000;

    transition: 150ms all ease;
    will-change: all;

    &:hover {
      background-color: transparent;

      & a {
        color: #fff;
      }
    }
  }
`;

const ImageContainer = styled.div`
  margin: ${props => props.margin};

  display: block;
  width: 100%;

  object-fit: cover;
  max-height: 560px;
`;

const HeroImage = ({
  image,
  margin,
  position,
  srcWebp,
  srcSetWebp,
  callToAction,
  buttonText,
  link,
}) => {
  if (link !== undefined && link !== null) {
    return (
      <HeroImageContainer>
        <ImageContainer margin={margin}>
          <GatsbyImage
            image={image.gatsbyImageData}
            position={position}
            loading="lazy"
          />
        </ImageContainer>
        <div>
          <h2>{callToAction}</h2>
          {link.url !== undefined && link.url !== null && (
            <button>
              <a href={link.url} target={link.target}>
                {buttonText}
              </a>
            </button>
          )}
          {/* <Link to={`/collections/${link}`}>{buttonText}</Link> */}
        </div>
      </HeroImageContainer>
    );
  } else {
    return (
      <HeroImageContainer>
        <ImageContainer margin={margin}>
          <GatsbyImage
            image={image.gatsbyImageData}
            position={position}
            loading="lazy"
          />
        </ImageContainer>
      </HeroImageContainer>
    );
  }
};

export default HeroImage;
