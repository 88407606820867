import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Previews
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import Container from '../components/global/container';
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';

const ContactRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:first-of-type {
    padding: 0;
  }

  & h2 {
    font-size: 18px;
  }
`;

const Title = styled.div`
  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;

  padding: ${props => props.padding};

  & h2 {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    padding: 0;

    & h1 {
      font-size: 18px;
    }
  }
`;

const Location = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  padding-top: 15px;
  padding-bottom: 30px;

  border-bottom: 1px solid #e8e8e8;
`;

const ContactContainer = styled.div`
  padding: 60px 0 0 30px;

  & h2 {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;
  }

  @media (max-width: 768px) {
    padding: 30px 10px 0;
  }
`;

const Country = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

const Contact = ({ data }) => {
  const stockists = data.prismicStockists.data.body.map(location => {
    const countryLocations = location.items.map((singleLocation, index) => (
      <Col col={12} md={6} lg={4} xl={3} key={location.id + '___' + index}>
        <Location>
          <div
            dangerouslySetInnerHTML={{
              __html: singleLocation.location_text.html,
            }}
          />
        </Location>
      </Col>
    ));

    return (
      <Col col={12} key={location.id}>
        <Country>
          <Row>
            <Col col={12}>
              <Title
                dangerouslySetInnerHTML={{
                  __html: location.primary.country_name.html,
                }}
              />
            </Col>
          </Row>

          <Row>{countryLocations}</Row>
        </Country>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <Helmet
        title={`Contact – Mourne Textiles`}
        meta={[
          {
            name: 'description',
            content: `A family-run, design-led manufacturer of handwoven products based in County Down, Northern Ireland`,
          },
          {
            name: 'og:image',
            content: `${data.prismicContactUs.data.image.url}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${data.prismicContactUs.data.image.url}`,
          },
          {
            name: 'og:description',
            content: `A family-run, design-led manufacturer of handwoven products based in County Down, Northern Ireland`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `Mourne Textiles`,
          },
          {
            name: 'twitter:description',
            content: `A family-run, design-led manufacturer of handwoven products based in County Down, Northern Ireland`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${data.prismicContactUs.data.image.url}`,
          },
        ]}
      />

      <ContactRow>
        <Col col={12} md={6}>
          <img
            srcSet={`${data.prismicContactUs.data.image.url}&h=560&crop=entropy&q=80 1x,
            ${data.prismicContactUs.data.image.url}&h=560&crop=entropy&dpr=2&q=80 2x,
            ${data.prismicContactUs.data.image.url}&h=560&crop=entropy&dpr=3&q=80 3x`}
            src={`${data.prismicContactUs.data.image.url}&h=560&crop=entropy&q=80`}
            alt={data.prismicContactUs.data.image.alt}
          />
        </Col>

        <Col col={12} md={6} lg={5}>
          <ContactContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicContactUs.data.contact_us_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicContactUs.data.contact_us_text.html,
              }}
            />
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicContactUs.data.trade_enquiry_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicContactUs.data.trade_enquiry_text.html,
              }}
            />
          </ContactContainer>
        </Col>
      </ContactRow>
      <Container>
        <Row>
          <Col col={12}>
            <Title
              padding={'60px 0 30px 0'}
              dangerouslySetInnerHTML={{
                __html: data.prismicStockists.data.title.html,
              }}
            />
          </Col>

          <Col col={12}>
            <Row>{stockists}</Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withPrismicPreview(Contact);

export const query = graphql`
  query ContactQuery {
    prismicContactUs {
      data {
        image {
          url
          alt
        }
        contact_us_title {
          html
        }
        contact_us_text {
          html
        }
        trade_enquiry_text {
          html
        }
        trade_enquiry_title {
          html
        }
      }
    }
    prismicStockists {
      data {
        body {
          ... on PrismicStockistsDataBodyCountryTitle {
            id
            primary {
              country_name {
                html
              }
            }
            items {
              location_text {
                html
              }
            }
          }
        }
        title {
          html
        }
      }
    }
  }
`;
