import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useDebounce } from 'react-use';

// Components
import { Col } from '../global/col';
import { GiftWrap } from '../gift-wrap/gift-wrap';
import { StoreContext } from '../context/store-context';
import LineItem from '../cart/line-item';
import FormatPrice from '../utils/format-price';

const CartContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 30px 15px;

  min-height: 100%;

  & h2,
  & p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

const CheckoutButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 35px;

  margin: 20px 0 0 0;
  background-color: #934e2a;

  transition: 150ms all ease;
  will-change: all;

  & a {
    display: block;
    width: 100%;

    text-transform: uppercase;
    text-align: center;

    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.1em;
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    color: #fff;
  }

  &:hover {
    background-color: rgba(147, 78, 42, 0.8);
  }
`;

const GiftMessage = styled.div`
  margin: 20px 0;

  & textarea {
    display: block;

    width: 100%;
    border: 1px solid #e8e8e8;
    padding: 10px;

    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;

    resize: none;

    &:focus {
      outline: 0;
    }

    ::-webkit-input-placeholder {
      letter-spacing: 0.3px;
    }
    ::-moz-placeholder {
      letter-spacing: 0.3px;
    }
    :-ms-input-placeholder {
      letter-spacing: 0.3px;
    }
    :-moz-placeholder {
      letter-spacing: 0.3px;
    }
    ::placeholder {
      letter-spacing: 0.3px;
    }
  }
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & p {
    margin: 0;
  }

  border-top: 1px solid #934e2a;
  padding-top: 20px;
`;

const CartEmpty = styled.div`
  width: 100%;

  & p {
    text-align: center;
    text-transform: capitalize;
    margin: 0;
  }
`;

const SlideOutCart = () => {
  const [textAreaValue, setTextAreaValue] = useState(``);

  const data = useStaticQuery(graphql`
    {
      prismicGiftWrapping {
        data {
          is_gift_wrap_option_active
          text
        }
      }
      shopifyProduct(handle: { eq: "gift-wrap" }) {
        id
        handle
        shopifyId
        options {
          shopifyId
          name
          values
        }
        storefrontId
        variants {
          id
          title
          price
          storefrontId
          shopifyId
          availableForSale
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
          }
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  `);

  const product = data.shopifyProduct;

  const { checkout, client } = useContext(StoreContext);

  const [debouncedValue, setDebouncedValue] = useState('');
  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(textAreaValue);
    },
    500,
    [textAreaValue]
  );

  useEffect(() => {
    if (debouncedValue.length >= 1) {
      // Add in cart note
      const input = { note: debouncedValue };
      client.checkout.updateAttributes(checkout.id, input).then((checkout) => {
        // Do something with the updated checkout
      });
    }
  }, [debouncedValue]);

  const line_items = checkout.lineItems
    .filter((line_item) => line_item.title !== 'Gift Wrap')
    .map((line_item) => {
      return <LineItem key={line_item.id.toString()} line_item={line_item} />;
    });

  let checkoutUrl =
    checkout?.webUrl !== undefined
      ? checkout?.webUrl.replace(
          'mournetextiles.com/cart/c/',
          'mourne-textiles.myshopify.com/checkouts/cn/'
        )
      : checkout?.webUrl;

  return (
    <CartContainer>
      {line_items}

      {line_items.length === 0 && (
        <CartEmpty>
          <p className="cart-empty">Shopping Basket Empty</p>
        </CartEmpty>
      )}

      <Col col={12} alignSelf="end">
        <GiftMessage>
          <textarea
            id="note"
            name="note"
            rows="6"
            placeholder="Add a gift message (optional)"
            value={textAreaValue}
            onChange={({ currentTarget }) => {
              setTextAreaValue(currentTarget.value);
            }}
          />
        </GiftMessage>

        {/* {data.prismicGiftWrapping.data.is_gift_wrap_option_active === true &&
          product !== null && <GiftWrap product={product} data={data} />} */}

        <TotalContainer>
          <h2>Total</h2>
          <p>
            {checkout.currencyCode !== undefined &&
              FormatPrice(checkout.totalPrice.amount, checkout.currencyCode)}
          </p>
        </TotalContainer>

        <CheckoutButton>
          <a href={checkoutUrl}>Checkout</a>
        </CheckoutButton>
      </Col>
    </CartContainer>
  );
};

export default SlideOutCart;
