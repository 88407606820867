import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const ThreeTwoImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ThreeTwoImage = ({ image, alt }) => {
  return (
    <ThreeTwoImageContainer>
      <div>
        <GatsbyImage image={image.gatsbyImageData} alt={alt} loading="lazy" />
      </div>
    </ThreeTwoImageContainer>
  );
};

export default ThreeTwoImage;
