import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Garnett Light', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    font-weight: 300;

    font-size: 14px;
    line-height: 24px;

    letter-spacing: 0.04em;
    font-feature-settings: 'ss17' on, 'salt' on, 'ss01' on, 'liga' off;

    font-weight: normal;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -webkit-tap-highlight-color: transparent;

    color: #323232;
  }

  strong{
    font-family: 'Garnett Regular', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif !important;
    font-weight: normal;

    margin-top: 2em;
    display: block;
  }

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;

    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }

  }

  h2,
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;

    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  h4 {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;

    margin: 0;
  }

  video,
  img {
    width: 100%;
    display: block;
  }

  em {
    font-family: 'Garnett Light Italic', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  }

  /*--------------------------------------------------------------
    ## Links
    --------------------------------------------------------------*/
  a {
    color: #323232;
    text-decoration: none;

    cursor: pointer;
    transition: 150ms color ease;
  }

  a:visited {
    color: #323232;
  }

  a:hover,
  a:focus,
  a:active {
    color: #934e2a;
  }

  a:hover,
  a:active,
  a:focus {
    outline: 0;
  }

  a > img {
    transition: 150ms opacity ease;
  }

  a:hover > img {
    opacity: 0.75;
  }


    /*--------------------------------------------------------------
    ## Inputs
    --------------------------------------------------------------*/

input {

  font-family: 'Garnett Regular', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
}


  /*--------------------------------------------------------------
    ## Button
    --------------------------------------------------------------*/

    button {
      border:0;
      padding: 0;

      cursor: pointer;
      -webkit-appearance: none !important;

      font-family: 'Garnett Regular', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif !important;
      line-height: 35px;
      letter-spacing: 0.5px;

      font-size: 13px;

      background: #fff;
      color: inherit;

      &:focus{
        outline: 0;
      }
    }


  /*--------------------------------------------------------------
    ## Menu
    --------------------------------------------------------------*/
.bm-cross-button{
  display: none;
}


    /* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;

  &:focus{
    outline: none;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 0;
  font-size: 15px;
  height: calc(100% - 80px) !important;

  @media (max-width: 1200px) {
  height: calc(100% - 60px) !important;
  }

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #323232;
}

/* Individual item */
.bm-item {
    overflow: hidden;

    &:focus{
    outline: none;
  }

  display: inline-block;
  list-style:none;

  margin:0;
  padding:0;


  & li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  & li a,
  & button {
    font-size: 20px;
    line-height: 1;
    font-family: 'Garnett Regular', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    text-align: left;

    line-height: 60px;
    padding: 0 30px;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid rgba(50,50,50,0.1);
  }


    & a:hover,
  & button:hover {
    color: #934e2a;
  }

  & button {
    padding-top: 5px;
    & > a {
      border-bottom: 0;
    }
  }

}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}



.gatsby-image-wrapper,
.gatsby-image-wrapper.gatsby-image-wrapper-constrained{
  width: 100%;
  height: 100%;
}


/*--------------------------------------------------------------
  # Cookie Consent
  --------------------------------------------------------------*/


.cookie-consent-container{
  position:fixed;
  right:20px;
  bottom:20px !important;

  @media(max-width: 767px){
    left: 20px;
    bottom: 20px !important;
  }

  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.5px;

  align-tems:baseline;
  background:#fff;
  color:#333;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;


  padding:15px;
  width:100%;
  max-width:300px;
  z-index:999;

  @media(max-width: 767px){
    max-width:280px;
  }

  border: 1px solid #e8e8e8;

  & a {
    text-decoration: underline;
    color:  #934e2a;
  }

  & button{
    width: 100px;
    height: 25px;
    margin: 15px 0 0 0;

  text-transform: uppercase;

  background-color: #934e2a;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #fff;

  transition: 150ms all ease;
  will-change: all;

    &:hover {
      background-color: rgba(147, 78, 42, 0.8);
    }
  }
}
`;

export default GlobalStyle;
