import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

const Image = styled.img`
  margin: ${props => props.margin};

  display: block;
  width: 100%;

  object-fit: cover;
  max-height: 560px;
`;

const CollectionsHeroImage = ({ image, margin }) => {
  return (
    <LazyLoad height={560}>
      <Image
        margin={margin}
        srcSet={`${image}&h=800&crop=entropy&q=80 1x,
        ${image}&h=800&crop=entropy&dpr=2&q=80 2x,
        ${image}&h=800&crop=entropy&dpr=3&q=80 3x`}
        src={`${image}&h=800&crop=entropy&q=80`}
        loading="lazy"
      />
    </LazyLoad>
  );
};

export default CollectionsHeroImage;
